import React from 'react';
import PropTypes from 'prop-types';
import Flickity from 'react-flickity-component';

import { PartnersWrpStyled } from './partners.styled';

const flickityOptions = {
  initialIndex: 0,
  autoplay: true,
  pauseAutoPlayOnHover: false,
  pageDots: false,
  contain: true,
  cellAlign: 'center',
  watchCSS: true,
  prevNextButtons: false,
};
export default function Partners({ title, subTitle, logos }) {
  return (
    <>
      <PartnersWrpStyled className="has-background partner-section">
        <div className="partners-bg-wrp">
          <div className="partners-outer-wrp ves-container">
            <div className="partners-inner-wrp">
              <div className="partners-content-wrp">
                {title && <h2 className="h2">{title}</h2>}
                {subTitle && <div className="tagline">{subTitle}</div>}
              </div>
              <div className="partners-images-wrp">
                <Flickity
                  className="carousel-nav" // default ''
                  elementType="div" // default 'div'
                  options={flickityOptions} // takes flickity options {}
                  disableImagesLoaded={false} // default false
                  reloadOnUpdate // default false
                  static // default false
                >
                  {logos.map((item) => (
                    <div className="image-item" key={item.logo.localFile.id}>
                      <img src={item.logo.localFile.publicURL} alt="partner-logo" />
                    </div>
                  ))}
                </Flickity>
              </div>
            </div>
          </div>
        </div>
      </PartnersWrpStyled>
    </>
  );
}

Partners.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      logo: PropTypes.shape({
        localFile: PropTypes.shape({
          publicURL: PropTypes.string,
        }),
      }),
    }),
  ),
};

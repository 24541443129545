import tw, { styled } from 'twin.macro';
// eslint-disable-next-line import/no-extraneous-dependencies
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

export const CTAWrpStyled = styled.div`
  ${tw`relative`}
  z-index:1;

  .cta-bg-wrp {
    ${tw`my-5 md:my-16 lg:my-24 pt-28 pb-20`}

    .cta-inner-wrp {
      ${tw`grid grid-cols-1 md:grid-cols-2 md:gap-6 relative`}

      .cta-content-wrp {
        ${tw`mb-11 md:m-0 md:col-start-2 md:pl-11 lg:pl-0`}

        h2 {
          ${tw`text-white text-center mb-7 md:text-left`}
        }

        .content {
          ${tw`text-white text-center mb-10 md:-mb-3 md:text-left`}
        }

        .download {
          max-width: 320px;
          margin: 0 auto;

          @media (min-width: ${fullConfig.theme.screens.md}) {
            ${tw`md:mt-12`}
            max-width: 180px;
            margin: 0;
          }
        }
      }

      .cta-image-wrp {
        ${tw`md:absolute -top-40 left-0 text-center md:ml-0 mx-auto`}
        box-shadow: -30px 27px 34px rgba(0,0,0,0.4);
        max-width: 305px;

        @media (min-width: 768px) {
          top: 50%;
          transform: translateY(-50%);
          max-width: 318px;
          margin-top: -16px;
        }

        .gatsby-image-wrapper-constrained {
          display: block;
        }
      }

      &.cta-one-col {
        ${tw`grid grid-cols-1 gap-0`}
        max-width: 572px;
        margin: 0 auto;

        h2 {
          ${tw`text-center`}
        }

        .content {
          ${tw`text-center`}
        }

        .download {
          ${tw`mx-auto`}
        }
      }

      &.image-right {
        .cta-content-wrp {
          ${tw`md:col-start-auto md:pl-14`}
        }

        .cta-image-wrp {
          ${tw`right-0 left-auto`}
        }
      }
    }

    &.bg-secondary-orange {
      .btn-cta {
        ${tw`bg-primary-dark border-primary-dark hover:bg-success hover:border-success`}
      }
    }
  }
`;

import tw, { styled } from 'twin.macro';
// eslint-disable-next-line import/no-extraneous-dependencies
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

export const PartnersWrpStyled = styled.div`
  ${tw`bg-gray-lighter pt-28 pb-28 md:pt-40 md:pb-40 lg:pt-44 lg:pb-44`}

  .partners-inner-wrp {
    ${tw`relative mx-auto`}

    .partners-content-wrp {
      ${tw`mx-auto md:mb-10 lg:mb-16`}

      max-width: 545px;

      h2 {
        ${tw`text-primary-dark mx-auto mb-3 text-center`}

        max-width: 290px;

        @media (min-width: ${fullConfig.theme.screens.md}) {
          max-width: 100%;
        }
      }

      .tagline {
        ${tw`text-gray-dark text-xl text-center mb-7`}

        @media (min-width: ${fullConfig.theme.screens.lg}) {
          max-width: 376px;

          ${tw`mx-auto`}
        }
      }
    }

    .partners-images-wrp {
      ${tw`flex justify-center md:justify-between flex-wrap -mx-9 md:mx-auto`}

      max-width: 912px;

      .carousel-nav {
        ${tw`flex justify-center md:justify-between`}

        .image-item {
          ${tw`mx-8 lg:mx-6`}
          min-width: 180px;
        }

        &::after {
          content: 'flickity';
          display: none; /* hide :after */

          @media (min-width: ${fullConfig.theme.screens.lg}) {
            /* disable Flickity for large devices */
            content: '';
          }
        }
      }
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { CTAWrpStyled } from './cta.styled';

export default function CTA({
  ctaTitle,
  ctaImage,
  ctaContent,
  ctaButtonText,
  ctaDownloadLink,
  ctaBgColor,
  imageRight,
  ctaOneCol,
}) {
  return (
    <CTAWrpStyled className="cta-section">
      <div className={`cta-bg-wrp ${ctaBgColor ? 'bg-primary' : 'bg-secondary-orange'}`}>
        <div className="cta-outer-wrp ves-container">
          <div
            className={`cta-inner-wrp ${ctaOneCol ? 'cta-one-col' : ''} ${
              imageRight ? '' : 'image-right'
            }`}>
            <div className="cta-content-wrp">
              {ctaTitle && <h2 className="h2">{ctaTitle}</h2>}
              {ctaContent && <div className="content">{ctaContent}</div>}
              {ctaDownloadLink && (
                <a
                  href={ctaDownloadLink}
                  download
                  className="btn-cta download"
                  target="_blank"
                  rel="noreferrer">
                  {ctaButtonText}
                </a>
              )}
            </div>
            {ctaImage && (
              <div className="cta-image-wrp">
                <GatsbyImage image={getImage(ctaImage)} alt="CTA" />
              </div>
            )}
          </div>
        </div>
      </div>
    </CTAWrpStyled>
  );
}

CTA.propTypes = {
  ctaTitle: PropTypes.string,
  ctaImage: PropTypes.shape({
    ctaFeaturedImage: PropTypes.shape({}),
  }),
  ctaContent: PropTypes.string,
  ctaButtonText: PropTypes.string,
  ctaDownloadLink: PropTypes.string,
  ctaBgColor: PropTypes.bool,
  imageRight: PropTypes.bool,
  ctaOneCol: PropTypes.bool,
};
